import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { HomepageDTO } from '@DTO/HomepageDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

const IndexRoute = () => {
  const pageData = HomepageDTO();

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject([{ current: true, label: 'Home', url: '' }]))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={pageData.metaData}
        breadcrumbs={null}
        stickedForm={pageData.stickedForm}
        bannerContactInfo={undefined}
      >
        <PageComponents components={pageData.components} />
      </Layout>
    </>
  );
};

export default IndexRoute;
